import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import type {State} from '../../stateType'
import {actions as shopActions} from '../Shop/state'

export const GeoLocationLoader = () => {
  const {checkoutId} = useSelector(({shop}: State) => ({
    checkoutId: shop.checkoutId,
  }))

  /**
   * Capture the initial state of having a checkoutId on load
   * to prevent the locale from being set based on geolocation
   * if the checkoutId is present.
   */
  const [shouldGetShopLocale] = useState(!checkoutId)

  const dispatch = useDispatch()
  useEffect(() => {
    if (shouldGetShopLocale) {
      dispatch(shopActions.setShopLocaleLoading(true))
      fetch('/api/v1-compat/locale').then(async response => {
        const {country} = await response.json()
        dispatch(shopActions.setShopLocale(country))
        dispatch(shopActions.setShopLocaleLoading(false))
      }).catch(err => {
        console.error(err)
        dispatch(shopActions.setShopLocaleLoading(false))
      })
    } else {
      dispatch(shopActions.setShopLocaleLoading(false))
    }
  }, [dispatch, shouldGetShopLocale])

  return null
}
