import React, {useState} from 'react'
import {useMutation} from '@apollo/client'
import {useSelector} from 'react-redux'
import {captureExcepton} from '@sentry/browser'
import {analytics} from '../../common/analytics'
import {uiLocations} from '../../common/constants'

import type {State} from '../../stateType'

import Button from '../Button'

import styles from './AddOnCTA.less'

import {ADD_LINE_ITEM} from '../Product/index'

export default ({item}) => {
  const [loading, setLoading] = useState(false)
  const [itemAdded, setItemAdded] = useState(false)

  const product = item && item.product
  const productId = product && product.variants && product.variants.edges[0].node.id

  if (!productId) return null

  const isSoldOut = product && !product.availableForSale

  const {checkoutId, shopLocale} = useSelector((state: State) => state.shop)

  const [updateBasket] = useMutation(ADD_LINE_ITEM, {
    variables: {
      cartId: checkoutId,
      lines: [{
        quantity: 1,
        merchandiseId: productId,
        attributes: [{
          key: '_uiLocation', // the underscore prefix hides this on the shopify checkout page
          value: uiLocations.basketAddOns,
        }],
      }],
      country: shopLocale,
    },
    context: {
      clientName: 'shopify',
    },
  })

  const handleClick = async () => {
    if (isSoldOut) return
    setLoading(true)

    try {
      await updateBasket()
    } catch (err) {
      console.log(err)
      captureExcepton(err)
      setLoading(false)
      return
    }

    setLoading(false)

    setItemAdded(true)
    setTimeout(() => setItemAdded(false), 3000)

    analytics.track(
      'add_on_product_cta_clicked',
      {ui_location: uiLocations.basketAddOns, product_title: product.title},
      ['ga']
    )
  }

  const text = isSoldOut ? 'SOLD OUT' : itemAdded ? 'ADDED' : 'ADD TO BASKET'

  return (
    <Button
      text={text}
      className={isSoldOut ? styles.SoldOut : styles.AddOnCTA}
      onClick={handleClick}
      loading={loading}
      loadingColour={'#000'}
      disabled={isSoldOut}
    />
  )
}
