// @flow
import React from 'react'
import queryString from 'query-string'
import striptags from 'striptags'
import Cookies from 'js-cookie'

import type {ShowType, PageSize, MatchType} from '../../common/types'
import {broadcastStates, genericItemTypes, signupSources, uiLocations} from '../../common/constants'
import {itemize, itemizeCollection} from '../../common/itemize'
import {brandIds} from '../../common/brands'
import previewableQueryVariables from '../../common/previewableQueryVariables'
import {isUserAllowedToWatch} from '../../common/digitalProducts'
import imageUrl from '../../common/imageUrl'
import {makeQueue} from '../../common/makeQueue'

import GET_SHOW from './Query'

import Card from '../Card'
import {LowHeatPageOverlay} from '../LowHeatIcon'
import Artists from '../Artists/'
import Background from '../Background/'
import BrandedStyleWrapper from '../BrandedStyleWrapper'
import ParentShowPanel from '../ParentShowPanel'
import EmbeddedShow from './EmbeddedShow'
import Loading from '../Loading/'
import Modal from '../Modal/'
import NotFound from '../NotFound/'
import Page from '../Page/'
import Player from '../Player'
import Query from '../Query'
import Rsvp from '../Rsvp'
import ShopSlider from '../ShopSlider'
import Slider from '../Slider/'
import CitiesGrid from '../CitiesGrid'
import Share from '../Share'

import styles from './Show.less'
import {analytics} from '../../common/analytics'
import ShowInfo from '../ShowInfo'

type Props = {
  location: {
    search: String,
  },
  match: MatchType,
  staticContext: {
    signature: String,
  },
}

type ShowProps = {
  show: ShowType,
  pageSize: PageSize,
  location: {
    pathname: String,
    search: String,
  },
  isLoggedIn: boolean,
  loading: boolean,
  rsvpOpen: boolean,
  refetch: Function,
  openRsvp: () => void,
  closeRsvp: () => void,
  openGate: (signupSource: string, uiLocation: string, itemId: ?number, itemType: ?string) => void,
}

const sortAlphabetically = (a, b) => {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

export default (props: Props) => {
  return <Query Component={Show} props={props} query={GET_SHOW} variables={previewableQueryVariables(props)} />
}

class Show extends React.Component {
  props: ShowProps

  state: {
    hasGoneLive: boolean,
  }

  constructor(props: ShowProps) {
    super(props)
    this.state = {
      hasGoneLive: false,
    }
  }

  componentDidUpdate = (prevProps: ShowProps) => {
    const {refetch} = this.props

    const justLoggedIn = !prevProps.isLoggedIn && this.props.isLoggedIn

    // If a user has just logged in, refetch the show
    if (justLoggedIn) refetch()

    this.checkToAutomaticallyOpenRSVP()
  }

  componentDidMount = () => {
    // Refetch in the client if show is geoblocked or user is logged inl
    const hasGeotargets = this.props.show && this.props.show && this.props.show.has_geotargets
    if (hasGeotargets || this.props.isLoggedIn) {
      this.props.refetch()
    }
    this.checkToAutomaticallyOpenRSVP()
  }

  checkToAutomaticallyOpenRSVP = () => {
    const show = this.props.show
    if (this.props.isLoggedIn && show && Cookies.get('openRsvpAfterLogin') === show.slug) {
      this.openRsvp()
      Cookies.remove('openRsvpAfterLogin')
    }
  }

  handleRsvpClick = () => {
    if (this.props.isLoggedIn) {
      this.openRsvp()
    } else {
      this.openGate()
    }
  }

  openRsvp = () => {
    const rsvp = this.props.show.user_rsvp_status
    if (rsvp && rsvp.status) return
    analytics.track('rsvp_open', {ui_location: uiLocations.show}, ['ga'])
    this.props.openRsvp()
  }

  openGate = () => {
    this.props.openGate(signupSources.inviteRequest, uiLocations.show, this.props.show.id, genericItemTypes.show)
    Cookies.set('openRsvpAfterLogin', this.props.show.slug)
  }

  closeRsvp = () => {
    analytics.track('rsvp_close', {ui_location: uiLocations.show}, ['ga'])
    this.props.closeRsvp()
  }

  render() {
    const {show, loading, location, pageSize, refetch, rsvpOpen} = this.props

    if (!loading && !show) return <NotFound />
    if (loading && !show) return <Loading />

    const isEmbed = queryString.parse(location.search).embedded === 'true'
    const isMinimal = queryString.parse(location.search).minimal === 'true'
    if (isEmbed) {
      return <EmbeddedShow show={show} pageSize={pageSize} minimal={isMinimal} />
    }

    const {
      artists,
      brand,
      background_image,
      consent_boxes,
      minimum_age,
      phone_number_required,
      rsvp_gender_mode,
      recordings,
      requires_login,
      suggestions,
      thumbnail_image,
      title,
      trailer_video,
      redirect_url,
      sponsor,
      description,
      summary,
      child_shows,
      theme,
      email_subscription_tags,
    } = show

    const showSuggestions = suggestions || (recordings && recordings[0] && recordings[0].suggestions)

    const isBoilerRoom = !show.brand || (show.brand && show.brand.id === brandIds.boilerroom)
    const isLowHeat = show.brand && show.brand.id === brandIds.lowheat

    const isParentShow = child_shows && child_shows.length > 0
    const isChildShow = show.parent_show && show.parent_show.child_shows.length > 0
    const parentShow = isChildShow && show.parent_show
    const parentShowArtists =
      isParentShow &&
      [].concat.apply(
        [],
        child_shows.map(child_show => child_show.artists),
      )

    const hasArtists = (parentShowArtists && parentShowArtists.length > 0) || artists.length > 0
    const showArtists = !show.hide_artists && show.broadcast_status !== broadcastStates.archived && hasArtists
    const isFourThree = brand.id === brandIds.fourthree
    const backgroundGradient = (theme && theme.background_color) || (isFourThree && '#FFFFFF') || '#000000'

    // fades either the background_image if populated, or solid grey into the backgroundGradient
    const shouldGradiateBackgroundImage = background_image ? 'rgba(0,0,0,0)' : 'rgba(66, 66, 66, 1)'

    return (
      <Page
        title={title}
        description={summary || striptags(description)}
        image={thumbnail_image || background_image}
        requiresLogin={requires_login}
        redirectUrl={redirect_url}
        brandId={show.brand.id}
        disableScroll={this.props.rsvpOpen}
        ssr={true}
        theme={theme}
        optInGateTags={email_subscription_tags}
        disableOptInGate={true}
      >
        <BrandedStyleWrapper styles={styles} classNameKey='Wrapper'>
          {background_image &&
            <div>
              <Background
                image={background_image}
                video={trailer_video}
                playing={true}
                muted={true}
                className={styles.Background}
              />
              <div
                className={styles.Gradient}
                style={{
                  background: `linear-gradient(to top, ${backgroundGradient} 30%, ${shouldGradiateBackgroundImage} 100%)`,
                }}
              />
            </div>
          }

          <PlayerWrapper show={show} />

          <div className={styles.Show}>
            <div className={styles.FlyerShareWrapper}>
              <ShowFlyer show={show} />
              <Share />
            </div>

            <div className={styles.BroadCastInfoAndPlaylistWrapper}>
              <ShowInfo handleRsvpClick={this.handleRsvpClick} show={show} />
              {/* Show Page Session Playlist */}
              {recordings && recordings.length > 0 &&
                <div className={styles.Recordings}>
                  {recordings.map(recording => (
                    <Card
                      key={recording.id}
                      item={itemize(recording, genericItemTypes.recording)}
                      queue={makeQueue(recording, recordings)}
                      uiLocation={uiLocations.showPageRecordingCard}
                      horizontal
                    />
                  ))}
                </div>
              }

              {showArtists &&
                <div className={styles.ArtistsWrapper}>
                  <Artists
                    artists={
                      (parentShowArtists && parentShowArtists.sort(sortAlphabetically)) ||
                      [...artists].sort(sortAlphabetically)
                    }
                    pageSize={pageSize}
                  />
                </div>
              }

              {isParentShow &&
                <div className={styles.ChildShowsWrapper}>
                  <h3 className={styles.ChildShowsHeader}>EVENTS</h3>
                  {child_shows.map(show =>
                    <Card
                      item={itemize(show, genericItemTypes.show)}
                      key={`child-show-${show.id}`}
                      horizontal
                      uiLocation={uiLocations.showPageChildShowCard}
                    />)
                  }
                </div>
              }

            </div>
          </div>

          {isChildShow && <ParentShowPanel parentShow={parentShow} />}

          {(show.related_playlists || showSuggestions.length) &&
            <div className={styles.Sliders}>
              {show.related_playlists &&
                show.related_playlists.map(playlist => (
                  <Slider
                    title={playlist.title}
                    description={playlist.summary}
                    items={playlist.items}
                    key={`related-playlist-${playlist.id}`}
                  />
                ))
              }

              {showSuggestions.length ?
                <Slider
                  title={'Suggestions'}
                  items={itemizeCollection(showSuggestions, genericItemTypes.recording)}
                /> :
                null
              }

              <ShopSlider sponsor={sponsor} />

              {isBoilerRoom && <CitiesGrid />}
            </div>
          }

          {isLowHeat && <LowHeatPageOverlay />}

          <Modal closeModal={this.closeRsvp} modalOpen={rsvpOpen}>
            <Rsvp
              closeRsvp={this.closeRsvp}
              consentBoxes={consent_boxes}
              minimumAge={minimum_age}
              mobileRequired={phone_number_required}
              genderMode={rsvp_gender_mode}
              refetchShow={refetch}
              show={show}
              title={title}
            />
          </Modal>
        </BrandedStyleWrapper>
      </Page>
    )
  }
}

const PlayerWrapper = ({show}) => {
  const hasStream = show.youtube_video_id || show.embed_override_url
  if (hasStream && isUserAllowedToWatch(show.digital_products)) {
    return (
      <div className={styles.PlayerWrapper}>
        <Player
          youtubeId={show.youtube_video_id}
          overrideUrl={show.embed_override_url}
          geoblocked={show.geoblocked}
          title={show.title}
          displayChat={show.broadcast_status === broadcastStates.live}
        />
      </div>
    )
  }

  return null
}

const ShowFlyer = ({show}) => (
  <div className={styles.FlyerWrapper}>
    {show.flyer_image &&
      <img
        className={styles.Flyer}
        src={imageUrl(show.flyer_image, 800, 800)}
        alt={'Event flyer'}
      />
    }
  </div>
)
