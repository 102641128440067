import {useEffect} from 'react'

const ZENDESK_SCRIPT_ID = 'ze-snippet'

const loadScriptIfNotLoaded = () => {
  if (document.getElementById(ZENDESK_SCRIPT_ID)) {
    return
  }

  /**
   * Initialise settings before loading the script,
   * see: https://developer.zendesk.com/api-reference/widget/settings
   */
  window.zESettings = {
  }

  const script = document.createElement('script')
  script.id = ZENDESK_SCRIPT_ID
  script.src = 'https://static.zdassets.com/ekr/snippet.js?key=240fab31-fba3-42ca-9212-68935f74b7c1'
  script.async = true
  document.body.appendChild(script)
}

/**
 * Component that will auto show or hide the ZenDesk widget
 * when mounted or unmounted.
 */
export const ZenDesk = () => {
  useEffect(() => {
    loadScriptIfNotLoaded()
    window.zE && window.zE('webWidget', 'show')

    return () => {
      window.zE && window.zE('webWidget', 'hide')
    }
  }, [])

  return null
}
