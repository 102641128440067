// @flow
import {trendingPlaylists} from './constants'
import {recordingIsInPlaylist} from './chartHelpers'
import type {RecordingType} from './types'

export default (recording: ?RecordingType) => {
  if (!recording) return null

  const isInNewPlaylist = recordingIsInPlaylist(recording, trendingPlaylists.new)
  const isInTrendingPlaylist = recordingIsInPlaylist(recording, trendingPlaylists.trending)
  const shouldDisplayChartPosition = isInNewPlaylist || isInTrendingPlaylist

  return shouldDisplayChartPosition
}
