// @flow
import {createAction} from 'redux-actions'
import mapValues from 'lodash.mapvalues'
import Cookies from 'js-cookie'
import http from 'axios'
import {captureException} from '@sentry/browser'

import {shopCurrencies, currencyCodes, countryCodes} from '../../common/constants'
import {analytics} from '../../common/analytics'

export const getShopCurrencyCodeFromCountryCode = (countryCode: string) => {
  const currency = shopCurrencies.find(entry => entry.countryCodes.includes(countryCode)) // find the matching shop currency from the locale

  if (!currency) return currencyCodes.usd // if none are found, return USD as the default currency

  return currency.code // else return the currency
}

export const getShopLocaleFromCurrencyCode = (currencyCode: string) => {
  const currency = shopCurrencies.find(entry => entry.code === currencyCode) // find matching shop currency from the currencyCode

  if (!currency) return countryCodes.us // if none are found return US as the default locale

  return currency.countryCodes[0] // else return the first countryCode for the matching currency
}

export const types = {
  setCheckoutId: 'Shop/setCheckoutId',
  setShopLocale: 'Shop/setShopLocale',
  setShopLocaleLoading: 'Shop/setShopLocaleLoading',
  removeCheckoutId: 'Shop/removeCheckoutId',
}

const plainActions = mapValues(types, type => createAction(type))

const setCheckout = (id: string) => dispatch => {
  if (!Cookies.get('checkoutId')) {
    Cookies.set('checkoutId', id)
    dispatch(plainActions.setCheckoutId(id))
  }
}

const removeCheckout = () => dispatch => {
  if (Cookies.get('checkoutId')) {
    Cookies.remove('checkoutId')
    dispatch(plainActions.removeCheckoutId())
  }
}

const changeShopLocale = (locale: string) => dispatch => {
  dispatch(plainActions.setShopLocale(locale))
  analytics.track('shop_change_shop_locale', {locale}, ['ga'])
  Cookies.remove('checkoutId')
  dispatch(plainActions.removeCheckoutId())
}

const fetchCheckoutCompletion = () => {
  return (dispatch, getstate) => {
    const {shop} = getstate()

    http('https://boiler-room-tv.myshopify.com/api/2024-04/graphql.json', {
      method: 'POST',
      url: 'https://boiler-room-tv.myshopify.com/api/2024-04/graphql.json',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_ACCESS_TOKEN,
      },
      data: JSON.stringify({
        query: `{
        node(id: "${shop.checkoutId}") {
          ... on Cart {
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }`,
      }),
    })
      .then(res => {
        const checkout = res.data && res.data.data && res.data.data.node

        // if the checkout has been completed, remove the existing checkout state
        // if the checkout does not resolve, remove any existing checkout state
        if (!checkout) {
          dispatch(actions.removeCheckout())
        } else { // else set the checkoutId, and the shopLocale based on the current checkouts currencyCode
          dispatch(actions.setCheckoutId(shop.checkoutId))
          dispatch(actions.setShopLocale(getShopLocaleFromCurrencyCode(res.data.data.node.cost.subtotalAmount.currencyCode)))
          analytics.track('shop_existing_checkout_currency_change', {currency: res.data.data.node.cost.subtotalAmount.currencyCode}, ['ga'])
        }
      })
      .catch(error => {
        captureException(error)
        console.log(error)
      })
  }
}

const onLaunch = () => dispatch => {
  const checkoutId = Cookies.get('checkoutId')
  if (checkoutId) {
    dispatch(fetchCheckoutCompletion())
  }
}

export const actions = {
  ...plainActions,
  // thunks
  setCheckout,
  removeCheckout,
  changeShopLocale,
  onLaunch,
}

export type StateType = {
  checkoutId: string,
  shopLocale: string,
  shopLocaleLoading: boolean,
}

const initialState: StateType = {
  checkoutId: '',
  shopLocale: countryCodes.us,
  shopLocaleLoading: true,
}

export default (state: StateType = initialState, action: any) => {
  switch (action.type) {
    case types.setCheckoutId:
      return {
        ...state,
        checkoutId: action.payload,
      }
    case types.setShopLocale:
      return {
        ...state,
        shopLocale: action.payload,
      }
    case types.setShopLocaleLoading:
      return {
        ...state,
        shopLocaleLoading: action.payload,
      }
    case types.removeCheckoutId:
      return {
        ...state,
        checkoutId: '',
      }
    default:
      return state
  }
}
