// @flow
import React from 'react'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'

import {hasWindow} from '../../common/constants'

// Pages
import Account from '../Account'
import Article from '../Article'
import Basket from '../Basket'
import Cities from '../Cities'
import Location from '../Location'
import Contact from '../Contact'
import Events from '../Events'
import Favourites from '../Favourites'
import Feedback from '../Feedback'
import GenericList from '../GenericList'
import GenericPage from '../GenericPage'
import Homepage from '../Homepage'
import HomepageHandler from '../HomepageHandler'
import InviteRequests from '../InviteRequests'
import LoginPage from '../LoginPage'
import Navigation from '../Navigation'
import NotFound from '../NotFound'
import Product from '../Product'
import Recording from '../Recording'
import ScrollToTop from '../ScrollToTop'
import SearchPage from '../SearchPage'
import Show from '../Show'
import Shop from '../Shop'
import Newsletter from '../Newsletter'
import Upcoming from '../Upcoming'
import SizeGuide from '../SizeGuide'
import Braze from '../Braze'
import Schedule from '../Schedule'
import SignupPage from '../SignupPage'
import Artist from '../Artist'
import Umbro from '../Umbro'
import ChartsPage from '../ChartsPage'

// PersistentPlayer
import PersistentPlayer from '../PersistentPlayer'
import {GeoLocationLoader} from './GeoLocation'

export default () =>
  hasWindow ?
    <ScrollToTop>
      <GeoLocationLoader />
      <Navigation />
      <PersistentPlayer />
      <Routes />
      <Braze />
    </ScrollToTop> :
    <div>
      <Navigation />
      <Routes />
    </div>

// Use withRouter here to get around this error
// https://github.com/ReactTraining/react-router/issues/4354

const Routes = () => (
  <Switch>
    <Route path='/support' component={() => <Redirect to='/page/support' />} />
    <Route path='/mailout' component={() => <Redirect to='/signup/newsletter' />} />
    <Route path='/shop/newsletter' component={() => <Redirect to='/signup/newsletter' />} />
    <Route path='/newsletter' component={() => <Redirect to='/signup/newsletter' />} />
    <Route path='/playlist/top-10-all-time' component={() => <Redirect to='/charts' />} />
    <Route path='/playlist/top-10-new' component={() => <Redirect to='/charts' />} />

    <Route exact path='/' component={withRouter(HomepageHandler)} />
    <Route exact path='/video' component={withRouter(Homepage)} />
    <Route exact path='/audio' component={withRouter(Homepage)} />
    <Route exact path='/film' component={withRouter(Homepage)} />
    <Route exact path='/homepage/:id' component={withRouter(Homepage)} />
    <Route exact path='/about' component={() => <Redirect to='/page/about' />} />
    <Route exact path='/schedule' component={withRouter(Schedule)} />
    <Route exact path='/charts' component={ChartsPage} />
    <Route path='/schedule/upcoming' component={withRouter(Schedule)} />
    <Route path='/schedule/:slug' component={withRouter(Schedule)} />
    <Route path='/audio/:slug' component={Recording} />
    <Route path='/recording/:slug' component={Recording} />
    <Route path='/film/:slug' component={Recording} />
    <Route path='/article/:slug' component={Article} />
    <Route path='/artist/:slug' component={Artist} />
    <Route path='/cities' component={Cities} />
    <Route path='/city/:slug' component={Location} />
    <Route path='/continent/:slug' component={Location} />
    <Route path='/film/:slug' component={Recording} />
    <Route path='/product/:slug' component={withRouter(Product)} />
    <Route path='/recording/:slug' component={Recording} />
    <Route path='/session/:slug' component={withRouter(Show)} />
    <Route path='/feedback/:show_slug/:uuid' component={withRouter(Feedback)} />
    <Route path='/feedback/:show_slug' component={withRouter(Feedback)} />
    <Route path='/signup/:slug' component={SignupPage} />
    <Route path='/newsletter' component={Newsletter} />
    <Route path='/shop/size-guide' component={SizeGuide} />
    <Route path='/shop/:slug' component={Shop} />
    <Route path='/page/:slug' component={GenericPage} />
    <Route path='/events/:page' component={withRouter(Events)} />
    <Route path='/events' component={withRouter(Events)} />
    <Route path='/:type/:slug/:page' component={withRouter(GenericList)} />
    <Route path='/:type/:slug' component={withRouter(GenericList)} />
    <Route path='/basket' component={withRouter(Basket)} />
    <Route path='/contact' component={Contact} />
    <Route path='/login' component={withRouter(LoginPage)} />
    <Route path='/account' component={withRouter(Account)} />
    <Route path='/invite-requests' component={withRouter(InviteRequests)} />
    <Route path='/shop' component={Shop} />
    <Route path='/upcoming' component={Upcoming} />
    <Route path='/search' component={SearchPage} />
    <Route path='/favourites' component={withRouter(Favourites)} />
    <Route path='/umbro' component={Umbro} />

    <Route path='*' component={NotFound} />
  </Switch>
)
