// @flow
import React from 'react'
import {useQuery} from '@apollo/client'
import {captureException} from '@sentry/browser'
import Cookies from 'js-cookie'

import styles from './Product.less'
import type {ShopifyCheckoutType, ShopifyProductType, PageSize} from '../../common/types'
import {genericItemTypes, shopTheme, signupSources, uiLocations} from '../../common/constants'
import {getPrices} from '../../common/shop'
import {analytics} from '../../common/analytics'
import getShopifyProductId from '../../common/getShopifyProductId'
import captureMutationErrors from '../../common/captureMutationErrors'

import Button from '../Button'
import Link from '../Link'
import ShopLoading from '../ShopLoading'
import Page from '../Page'
import ShopSlider from '../ShopSlider'
import ShopNav from '../ShopNav'
import Share from '../Share'
import Signup from '../Signup'
import blackArrow from '../../images/blackArrowRight.svg'
import Redirect from '../Redirect'

import SIGNUP_BY_SLUG from '../SignupPage/Query'

type Props = {
  data: {
    product: ShopifyProductType,
  },
  loading: boolean,
  checkoutId: string,
  shopLocale: string,
  match: {
    params: {
      slug?: string,
    },
  },
  mutation: any,
  setCheckout: string => void,
  setError: string => void,
  pageSize: PageSize,
}

// this function sets up the UTM tracking & referrer
// as custom attributes if they are populated
const getCheckoutCustomAttributes = () =>
  ['utm_campaign', 'utm_source', 'utm_medium', 'referrer']
    .filter(key => Cookies.get(key))
    .map(key => ({
      key,
      value: Cookies.get(key),
    }))

export default class Product extends React.Component {
  state: {
    imageIndex: number,
    itemAdded: boolean,
    loading: boolean,
    selectedSize: string,
  }
  resetBasketTimeout: number

  constructor(props: Props) {
    super(props)
    this.state = {
      imageIndex: 0,
      itemAdded: false,
      loading: false,
      selectedSize: '',
    }
  }

  componentDidMount = () => this.trackGA()

  componentDidUpdate(prevProps: Props) {
    this.trackGA()
    const slug = this.props.match.params && this.props.match.params.slug
    const prevSlug = prevProps.match.params && prevProps.match.params.slug
    if (slug && prevSlug && prevSlug !== slug) this.setState({imageIndex: 0})
  }

  trackGA = () => {
    if (!this.props.loading && this.props.data && this.props.data.product) {
      const id = this.props.data.product.id
      const price = this.props.data.product.priceRange.minVariantPrice.amount
      const title = this.props.data.product.title
      analytics.trackConversion('AW-855500528/KDcoCInm8e8CEPDN95cD')
      analytics.track('page_view', {
        send_to: 'AW-855500528',
        ecomm_pagetype: 'product',
        ecomm_prodid: id,
        ecomm_totalvalue: price,
        items: [
          {
            id: id,
            price: price,
            name: title,
          },
        ],
      }, ['ga'])
      analytics.track('view_item', {
        currency: 'GBP',
        value: price,
        items: [
          {
            item_id: id,
            item_name: title,
            price: price,
          },
        ],
      }, ['ga'])
    }
  }
  onImagesScroll = () => {
    const ele = document.getElementById('images')
    const imageIndex = ele && Math.round(ele.scrollLeft / ele.offsetWidth)
    const differentImageIndex = imageIndex !== this.state.imageIndex

    if (typeof imageIndex === 'number' && differentImageIndex) this.setState({imageIndex})
    analytics.track('scroll_product_images_mobile', {}, ['ga'])
  }

  updateSelectedSize = (size: string) => {
    if (this.state.selectedSize === size) {
      this.setState({selectedSize: ''})
    } else {
      this.setState({selectedSize: size})
    }
  }

  addToBasket = () => {
    const productId = this.props.data.product.variants && this.props.data.product.variants.edges[0].node.id
    const merchandiseId = this.state.selectedSize || productId
    const lines = [{
      quantity: 1,
      merchandiseId,
    }]
    const cartId = this.props.checkoutId
    const locale = this.props.shopLocale
    this.setState({loading: true})
    if (cartId) {
      this.updateCheckout({lines, cartId, country: locale})
    } else {
      const cartInput = {
        lines,
        attributes: getCheckoutCustomAttributes(),
      }
      this.createCheckout({
        cartInput,
        country: locale,
      })
    }
  }

  createCheckout = (variables: Object) =>
    this.props
      .mutation({
        variables,
      })
      .then(res => {
        if (res.errors) {
          captureMutationErrors(res.errors)
          this.props.setError('Add to basket error, please try again.')
          this.setState({loading: false})
          return null
        }
        this.basketUpdated(res.data.cartCreate.cart)
        this.trackAddToCart()
      })
      .catch(error => {
        captureException(error)
        console.log(error)
        this.setState({loading: false})
      })

  updateCheckout = (variables: Object) =>
    this.props
      .mutation({
        variables,
        refetchQueries: ['ProductQuery', 'FetchBasket'],
      })
      .then(res => {
        if (res.errors) {
          captureMutationErrors(res.errors)
          this.props.setError('Add to basket error, please try again.')
          this.setState({loading: false})
          return null
        }
        this.basketUpdated(res.data.cartLinesAdd.cart)
        this.trackAddToCart()
      })
      .catch(error => {
        captureException(error)
        console.log(error)
        this.setState({loading: false})
      })

  trackAddToCart = () => {
    const product = this.props.data.product

    analytics.addToCart({
      product_id: product.id,
      product_title: product.title,
      product_value: product.priceRange.minVariantPrice.amount,
    })
  }

  basketUpdated = (checkout: ShopifyCheckoutType) => {
    this.props.setCheckout(checkout.id)
    this.setState({itemAdded: true, loading: false})
    this.resetBasketTimeout = setTimeout(() => this.setState({itemAdded: false}), 3000)
  }

  componentWillUnmount() {
    if (this.resetBasketTimeout) clearTimeout(this.resetBasketTimeout)
  }

  render() {
    const {data, loading, checkoutId, match, pageSize} = this.props

    if (loading) return <ShopLoading />

    const product = data && data.product
    if (!loading && !product) return <Redirect to={'/shop'} />

    const images = product.images.edges
    const sizes = product.variants && product.variants.edges
    const {price, compareAtPrice, discountPercent} = getPrices(product)
    const slug = match.params && match.params.slug
    const {imageIndex} = this.state

    const isLocked = product.tags && product.tags.includes('Locked')
    const isMembersOnly = product.tags && product.tags.includes('MEMBERSONLY')
    const isGetRestockNotification = product.tags && product.tags.includes('EnableRestockNotification')
    const isPasswordProtected = product.tags && product.tags.includes('PasswordProtected')
    const isSoldOutWithGetRestockEnabled = !product.availableForSale && isGetRestockNotification

    return (
      <Page
        title={product.title}
        description={product.description}
        iconColour='#000'
        image={images[0].node.url}
        invertColours={true}
        theme={shopTheme}
        subNavigation={<ShopNav />}
        enableSubscribeOverlay={true}
        isPasswordProtected={isPasswordProtected}
        optInGateTags={['shop', product.title]}
        requiresLogin={isMembersOnly}
        enableZendesk
      >
        <div className={styles.Wrapper}>
          <div className={styles.Container}>
            <Images images={images} imageIndex={imageIndex} onScroll={this.onImagesScroll} pageSize={pageSize} />
            <InfoPanel
              addToBasket={this.addToBasket}
              checkoutId={checkoutId}
              chooseSize={this.updateSelectedSize}
              itemAdded={this.state.itemAdded}
              loading={this.state.loading}
              compareAtPrice={compareAtPrice}
              discountPercent={discountPercent}
              price={price}
              product={product}
              selectedSize={this.state.selectedSize}
              sizes={sizes}
              isLocked={isLocked}
              isSoldOutWithGetRestockEnabled={isSoldOutWithGetRestockEnabled}
            />
          </div>
        </div>
        <div className={styles.ShopSlider}>
          <ShopSlider slug={slug} title={'Related Products'} />
        </div>
      </Page>
    )
  }
}

const Images = ({images, imageIndex, onScroll, pageSize}) => (
  <div className={styles.ImagesContainer}>
    <div id='images' className={styles.Images} onScroll={onScroll}>
      {images.map(image => (
        <img key={image.node.id} src={image.node.url} />
      ))}
    </div>

    {images.length > 1 &&
      <div className={styles.Circles}>
        {images.map((image, index) => (
          <div key={'circle_' + index} className={index === imageIndex ? styles.Circle_Filled : styles.Circle} />
        ))}
      </div>
    }
  </div>
)

const InfoPanel = ({
  addToBasket,
  checkoutId,
  chooseSize,
  itemAdded,
  loading,
  compareAtPrice,
  price,
  product,
  selectedSize,
  sizes,
  discountPercent,
  isLocked,
  isSoldOutWithGetRestockEnabled,
}) => {
  return (
    <div className={styles.InfoPanel}>
      <div className={styles.Title}>{product.title}</div>
      <Purchase
        addToBasket={addToBasket}
        checkoutId={checkoutId}
        chooseSize={chooseSize}
        itemAdded={itemAdded}
        loading={loading}
        price={price}
        compareAtPrice={compareAtPrice}
        discountPercent={discountPercent}
        selectedSize={selectedSize}
        sizes={sizes}
        product={product}
        isLocked={isLocked}
        isSoldOutWithGetRestockEnabled={isSoldOutWithGetRestockEnabled}
      />
    </div>
  )
}

const Purchase = ({
  addToBasket,
  checkoutId,
  chooseSize,
  itemAdded,
  loading,
  compareAtPrice,
  discountPercent,
  price,
  selectedSize,
  sizes,
  product,
  isLocked,
  isSoldOutWithGetRestockEnabled,
}) => (
  <div>
    {product.availableForSale &&
      <div className={styles.Prices}>
        <span className={styles.Price}>{price}</span>

        {compareAtPrice && <span className={styles.CompareAtPrice}>{compareAtPrice}</span>}

        {discountPercent && <span className={styles.DiscountPercent}>{discountPercent} off</span>}
      </div>
    }
    <CallToAction
      product={product}
      checkoutId={checkoutId}
      addToBasket={addToBasket}
      loading={loading}
      itemAdded={itemAdded}
      sizes={sizes}
      selectedSize={selectedSize}
      chooseSize={chooseSize}
      isLocked={isLocked}
      isSoldOutWithGetRestockEnabled={isSoldOutWithGetRestockEnabled}
    />
  </div>
)

const CallToAction = ({
  isLocked,
  product,
  checkoutId,
  loading,
  addToBasket,
  itemAdded,
  sizes,
  selectedSize,
  chooseSize,
  isSoldOutWithGetRestockEnabled,
}) => {
  const {data, loading: queryLoading} = useQuery(SIGNUP_BY_SLUG, {
    variables: {
      slug: isLocked ? 'product-coming-soon' : 'product-sold-out',
    },
  })

  if (queryLoading) return null

  const signup = data && data.signup_by_slug

  if (isLocked && signup) {
    return (
      <div className={styles.ProductSignup}>
        <Signup
          className={styles.ProductSignupText}
          signup={signup}
          tags={[product.title]}
          sigupSource={signupSources.shopSubscribe}
          uiLocation={uiLocations.productComingSoon}
          favouriteItemType={genericItemTypes.product}
          favouriteItemId={getShopifyProductId(product.id)}
        />
      </div>
    )
  }

  if (isSoldOutWithGetRestockEnabled && signup) {
    return (
      <div className={styles.ProductSignup}>
        <Signup
          className={styles.ProductSignupText}
          signup={signup}
          tags={[product.title]}
          sigupSource={signupSources.shopSubscribe}
          uiLocation={uiLocations.productComingSoon}
          favouriteItemType={genericItemTypes.product}
          favouriteItemId={getShopifyProductId(product.id)}
        />
      </div>
    )
  }

  const buttonText = itemAdded ? 'Added' : 'Add To Basket'
  const hasMultipleSizes = sizes && sizes.length > 1
  const noSelectedSize = !selectedSize && hasMultipleSizes
  const selectedSizeIsSoldOut =
    sizes && selectedSize && !!sizes.find(size => size.node.id === selectedSize && !size.node.availableForSale)

  return (
    <div className={styles.ProductInformationContainer}>
      {hasMultipleSizes &&
        <div className={styles.SizesContainer}>
          <div className={styles.Sizes}>
            {sizes &&
              sizes.map(size => (
                <Size key={size.node.id} chooseSize={chooseSize} size={size.node} selectedSize={selectedSize} />
              ))}
          </div>
        </div>
      }

      {selectedSizeIsSoldOut && signup ?
        <div className={styles.ProductSignup} style={{marginTop: 0}}>
          <Signup
            className={styles.ProductSignupText}
            signup={signup}
            tags={[product.title, 'Sold Out Product Subscriber']}
            sigupSource={signupSources.shopSubscribe}
            uiLocation={uiLocations.productComingSoon}
            favouriteItemType={genericItemTypes.product}
            favouriteItemId={getShopifyProductId(product.id)}
          />
        </div> :
        product.availableForSale ?
          <Button
            className={noSelectedSize ? styles.AddToBasket_disabled : styles.AddToBasket}
            disabled={noSelectedSize || itemAdded}
            loadingColour='#FFF'
            loading={loading}
            onClick={addToBasket}
            text={noSelectedSize ? 'Please select a size' : buttonText}
            type='button'
          /> :
          <Button className={styles.AddToBasket_soldout} disabled={true} text='Sold Out' />
      }

      {checkoutId &&
        <Link
          internalLink='/basket'
          className={styles.GoToBasket}
          uiLocation={uiLocations.productPageGoToBasketLink}
        >
          Go to basket
          <img src={blackArrow} />
        </Link>
      }

      <div className={styles.Description} dangerouslySetInnerHTML={{__html: product.descriptionHtml}} />

      <div className={styles.Share}>
        <Share dark />
      </div>

      <div className={styles.DeliveryAndReturns}>
        <a target='_blank' href='https://boilerroom.tv/page/shipping-and-returns'>
          Shipping & Returns
        </a>
      </div>
    </div>
  )
}

const Size = ({size, selectedSize, chooseSize}) => (
  <Button
    className={
      size.id === selectedSize ? styles.Size_selected : size.availableForSale ? styles.Size : styles.Size_soldout
    }
    onClick={() => chooseSize(size.id)}
    text={size.title}
    disableDefaultStyles={true}
    type='button'
  />
)
