// @flow
import {countries} from 'countries-list'

export const itemsPerPage = 24

// Braze tracking - to determine what a user signed up for
export const signupSources = {
  unknown: 'unknown',
  inviteRequest: 'invite_request',
  showSubscribe: 'show_subscribe',
  shopSubscribe: 'shop_subscribe',
  newsletterSubscribe: 'newsletter_subscribe',
  favouriteRecording: 'favourite_recording',
  digitalProduct: 'digital_product',
  signupPageSubscribe: 'signupPageSubscribe',
  favouriteCity: 'favourite_city',
  tracklist: 'tracklist_subscribe',
  worldTour: 'world_tour_subscribe',
}

export const consents = {
  boilerRoomMailingList: 'BOILER ROOM mailing list',
  fourThreeMailingList: '4:3 mailing list',
  websiteTermsOfUse: 'Website Terms of Use',
}

// GA4 tracking - to determine where a user interacts with the UI
export const uiLocations = {
  digitalProductCTA: 'digital_product_cta',
  favouriteToggle: 'favourite_toggle',
  footer: 'footer',
  popUp: 'pop_up',
  productComingSoon: 'product_coming_soon',
  productSoldOut: 'product_sold_out',
  productSoldOutSize: 'product_sold_out_size',
  optInGate: 'opt_in_gate',
  accountOptIn: 'account_opt_in',
  loginPage: 'log_in_page',
  loginGate: 'log_in_gate',
  newsletter: 'newsletter',
  show: 'show',
  signupPage: 'signup_page',
  cityPage: 'city_page',
  tracklist: 'tracklist',
  worldTour: 'world_tour_microsite',
  returnToSession: 'return_to_session',
  rsvpForm: 'rsvp_form',
  sliderHeader: 'slider_header',
  sliderCard: 'slider_card',
  sliderTile: 'slider_tile',
  stackedTile: 'stacked_tile',
  stackedPanel: 'stacked_panel',
  carousel: 'carousel',
  navigationLink: 'navigation_link',
  navigationLogo: 'navigation_logo',
  footerLink: 'footer_link',
  shopNavLink: 'shop_nav_link',
  videoNavLink: 'video_nav_link',
  favouritesPageCard: 'favourites_page_card',
  eventsPageCard: 'events_page_card',
  upcomingPageCard: 'upcoming_page_card',
  genericListPageCard: 'generic_list_page_card',
  schedulePageCard: 'schedule_page_card',
  searchResultsPageCard: 'search_results_page_card',
  showPageRecordingCard: 'show_recording_card',
  parentShowPanelCard: 'parent_show_panel_card',
  chartsPageCard: 'charts_page_card',
  artistPageCard: 'artist_page_card',
  lineupArtistCard: 'lineup_artist_card',
  showPageChildShowCard: 'show_page_child_show_card',
  citiesGrid: 'cities_grid',
  findYourCityLink: 'find_your_city_link',
  locationPageCitiesBackLink: 'location_page_cities_back_link',
  cityUpdatesLink: 'city_updates_link',
  shopProductCard: 'shop_product_card',
  productPageGoToBasketLink: 'product_page_go_to_basket_link',
  shopNavBasketLink: 'shop_nav_basket_link',
  globeHeroPanelCta: 'globe_hero_panel_cta',
  basketAddOns: 'basket_add_ons',
}

export const broadcastStates = {
  future: 'future',
  upcoming: 'upcoming',
  live: 'live',
  liveOffCamera: 'live_off_camera',
  relooping: 'relooping',
  offair: 'offair',
  archived: 'archived',
}

export const rsvpStates = {
  pending: 'p',
  successful: 's',
  unsuccessful: 'u',
}

export const playlistDisplayOptions = {
  cardSlider: 'card_slider',
  tileSlider: 'tile_slider',
  carousel: 'carousel',
  stackedPanels: 'stacked_panels',
  stackedTiles: 'stacked_tiles',
}

export const trendingPlaylists = {
  new: 'new',
  trending: 'trending',
  memberFavourites: 'member-favourites',
}

export const shopTheme = {
  background_color: '#FFF',
}

export const shopifyCollectionIds = {
  all: 'Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzQyMTk3NzEyOTQ4', // This is is used to grab all products to render on the ShopSlider component
  popular: 'gid://shopify/Collection/400889610456', // This gid is used to grab the popular items collection to display on the Basket page
  addOns: 'gid://shopify/Collection/426514350296', // This gid is used to query the Add Ons collection to display on the Basket page
}

export const currencyCodes = {
  gbp: 'GBP',
  usd: 'USD',
  aud: 'AUD',
  jpy: 'JPY',
  eur: 'EUR',
  cad: 'CAD',
}

export const countryCodes = {
  gb: 'GB',
  us: 'US',
  au: 'AU',
  jp: 'JP',
  ax: 'AX',
  ad: 'AD',
  at: 'AT',
  be: 'BE',
  cy: 'CY',
  ee: 'EE',
  fi: 'FI',
  fr: 'FR',
  gf: 'GF',
  tf: 'TF',
  de: 'DE',
  gr: 'GR',
  gp: 'GP',
  ie: 'IE',
  it: 'IT',
  xk: 'XK',
  lv: 'LV',
  lt: 'LT',
  lu: 'LU',
  mt: 'MT',
  mq: 'MQ',
  yt: 'YT',
  mc: 'MC',
  me: 'ME',
  nl: 'NL',
  pt: 'PT',
  re: 'RE',
  sm: 'SM',
  sk: 'SK',
  si: 'SI',
  es: 'ES',
  bl: 'BL',
  mf: 'MF',
  pm: 'PM',
  va: 'VA',
  ca: 'CA',
}

export const currencySymbols = {
  usd: '$',
  eur: '€',
  gbp: '£',
  aud: 'A$',
  jpy: '¥',
  cad: '$',
}

/*
  CODE:
  The 3 letter shorthand expression for a currency, i.e GBP. This is used to combine with the symbol i.e £ to create the display string in the currency dropdown '£ GBP'.
  This code is used to compare against the currencyCode value returned from the Shopify Storefront API resolvers when checking if we should clear the users basket.

  SYMBOL:
  The currency symbol, used to for displaying prices within the Shop, Basket and Product components in the corresponding currencies. ALso used with the code above
  to create display strings for the currency dropdown in the ShopNav.

  COUNTRY CODES:
  An array of 2 letter ISO codes for countries that match the currency for a region, for example USD contains ['US'], GBP contains ['GB'] etc. These values are derived from
  our Shopify Markets here: https://admin.shopify.com/store/boiler-room-tv/settings/markets. countryCodes is an array to maintain data structure consistency due to the vast
  list of countries within our 'EUROZONE' market on Shopify. Values from here are stored in the Shop State as storeLocale and passed to Shopify resolvers as part of their
  inContext directive, which returns the corresponding prices on product variants for the Market that matches the country code supplied.
*/

export type ShopCurrency = {
  code: string,
  symbol: string,
  countryCodes: Array<string>
}

// CountryCodes are arrays to keep the data structure consistent between currencies,
// this is due to shopify markets having multiple euro country codes.
export const shopCurrencies: Array<ShopCurrency> = [
  {
    code: currencyCodes.gbp,
    symbol: currencySymbols.gbp,
    countryCodes: [countryCodes.gb],
  },
  {
    code: currencyCodes.usd,
    symbol: currencySymbols.usd,
    countryCodes: [countryCodes.us],
  },
  {
    code: currencyCodes.aud,
    symbol: currencySymbols.aud,
    countryCodes: [countryCodes.au],
  },
  {
    code: currencyCodes.jpy,
    symbol: currencySymbols.jpy,
    countryCodes: [countryCodes.jp],
  },
  {
    code: currencyCodes.eur,
    symbol: currencySymbols.eur,
    countryCodes: [
      countryCodes.fr,
      countryCodes.ax,
      countryCodes.ad,
      countryCodes.at,
      countryCodes.be,
      countryCodes.cy,
      countryCodes.ee,
      countryCodes.fi,
      countryCodes.gf,
      countryCodes.tf,
      countryCodes.de,
      countryCodes.gr,
      countryCodes.gp,
      countryCodes.ie,
      countryCodes.it,
      countryCodes.xk,
      countryCodes.lv,
      countryCodes.lt,
      countryCodes.lu,
      countryCodes.mt,
      countryCodes.mq,
      countryCodes.yt,
      countryCodes.mc,
      countryCodes.me,
      countryCodes.nl,
      countryCodes.pt,
      countryCodes.re,
      countryCodes.sm,
      countryCodes.sk,
      countryCodes.si,
      countryCodes.es,
      countryCodes.bl,
      countryCodes.mf,
      countryCodes.pm,
      countryCodes.va,
    ],
  },
  {
    code: currencyCodes.cad,
    symbol: currencySymbols.cad,
    countryCodes: [countryCodes.ca],
  },
]

export const dropdowns = {
  currency: 'currency',
  collections: 'collections',
}

export const leftArrowKey = 37
export const rightArrowKey = 39

// The following values need to be kept in sync with constants.less
export const gutterSize = 16 // px
export const headerHeight = 80 // px
export const menuWidthSmall = 273 // px
export const menuWidthLarge = 300 // px
export const videoAspectRatio = 16 / 9

// The following values need to be kept in sync with responsive.less
// value represents the minimum width of each screen size
export const smallScreen = 420 // px
export const mediumScreen = 737 // px
export const largeScreen = 960 // px
export const extraLargeScreen = 1300 // px

export const numberOfColumns = 12

export const pageSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  extraLarge: 'extraLarge',
}

export const gridItemWidths = {
  small: 12,
  medium: 6,
  large: 4,
  extraLarge: 3,
}

export const backgroundImageDimensions = {
  small: {
    width: 600,
    height: 600,
  },
  medium: {
    width: 960,
    height: 540,
  },
  large: {
    width: 1400,
    height: 788,
  },
  extraLarge: {
    width: 2400,
    height: 1350,
  },
}

export const streamTypes = {
  youtube: 'youtube',
}

export const genericItemTypes = {
  article: 'article',
  show: 'show',
  recording: 'recording',
  playlist: 'playlist',
  sponsor: 'sponsor',
  product: 'product',
  link: 'link',
  city: 'city',
  artist: 'artist',
  digitalProduct: 'digital_product',
  signup: 'signup',
}

export const notificationTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
}

export const mediaFormats = {
  video: 'video',
  audio: 'audio',
}

export const showDateFormats = {
  hideDateAndTime: 'hide_date_and_time',
  tba: 'tba',
  monthYear: 'month_year',
  dayMonthYear: 'day_month_year',
  dayMonthYearStart: 'day_month_year_start',
  dayMonthYearStartEnd: 'day_month_year_start_end',
}

const sortedCountriesObject = Object.values(countries).sort((a: any, b: any) => {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
})

export const sortedCountries = sortedCountriesObject.map((country: any) => country.name)

export const unspecifiedGenderOption = 'unspecified'

export const genderOptions = [
  'female',
  'male',
  'non-binary',
  'transgender',
  'intersex',
  'other / not listed',
  unspecifiedGenderOption,
]

export const algoliaAppID = '4QU6A1P31C'
export const algoliaSearchKey = '8d66a97dc86607281a2b5e0ac3c88d1b'

export const hasWindow = typeof window !== 'undefined'
export const baseUrl = process.env.BASE_URL || (hasWindow ? window.location.host : 'boilerroom.tv')

/**
 * All allowed URL alternatives for the primary brand side (`boilerroom.tv`),
 * comma-separated.
 *
 * If the user is viewing the main website from any domain not listed here,
 * then they'll be redirected to {@link baseUrl}.
 *
 * Note this will include the port if specified
 */
export const baseUrlHostnameAlternatives = process.env.BASE_URL_ALTERNATIVES ? process.env.BASE_URL_ALTERNATIVES.split(',') : []

// 4:3
export const fourThreeGreen = '#62d38a'
export const fourThreeBrown = '#eaddce'
export const fourThreePink = '#cea3bb'
