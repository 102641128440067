import React from 'react'

import type {RecordingType} from '../../common/types'

import {hasFutureYoutubePublishDate, publishedInLastNDays, isPremieringOnYoutube} from '../../common/youtubePublicationDate'

import shouldDisplayChartPosition from '../../common/shouldDisplayChartPosition'

import IconWithText from '../IconWithText'

import styles from './RecordingStatus.less'
import Bolt from '../Svgs/Bolt'

export default ({recording, className}: {recording: RecordingType, className: string}) => {
  if (!recording) return null

  const isUpcoming = hasFutureYoutubePublishDate(recording)
  const isNewRecording = publishedInLastNDays(recording, 7)
  const isLive = isPremieringOnYoutube(recording)
  const shouldDisplayTrending = shouldDisplayChartPosition(recording)

  if (isLive) return <div className={[styles.Live, className].join(' ')}>LIVE</div>

  if (isUpcoming) return <div className={[styles.Upcoming, className].join(' ')}>UPCOMING</div>

  if (isNewRecording) return <div className={[styles.New, className].join(' ')}>NEW</div>

  if (shouldDisplayTrending) {
    return (
      <IconWithText
        icon={<Bolt className={styles.BoltIcon} />}
        text={'Trending'}
        color={'#FFE704'}
      />
    )
  }

  return null
}
