import {gql} from '@apollo/client'
import {CartFragment} from '../../common/fragments'

export const REMOVE_LINE_ITEM = gql`
  mutation (
    $cartId: ID!,
    $lineIds: [ID!]!,
    $country: CountryCode
  ) @inContext(country: $country) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...Cart
      }
    }
  }
  ${CartFragment}
`

export const UPDATE_LINE_ITEM = gql`
  mutation (
    $cartId: ID!,
    $lines: [CartLineUpdateInput!]!,
    $country: CountryCode
  ) @inContext(country: $country) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...Cart
      }
    }
  }
  ${CartFragment}
`
