// @flow
import type {BrandType} from './types'
import {baseUrl, baseUrlHostnameAlternatives} from './constants'

import imageUrl from './imageUrl'

export const lowHeatLogo = imageUrl(
  'https://cdn.boilerroom.tv/wp-content/uploads/2018/08/lowHeatLogo.png',
  1080,
  1080,
  1
)
export const boilerroomFbImage = 'https://cdn.boilerroom.tv/wp-content/uploads/2018/09/ogimage.jpg'
export const hardDanceImage = imageUrl(
  'https://boilerroom.tv/wp-content/uploads/2018/12/BR_HardDance_18_SPYDER.png',
  1448,
  1121,
  1
)
export const upcomingRecordingsImage = 'https://misc.boilerroom.tv/upcoming-recordings-holding-card.png'

export const brandIds = {
  boilerroom: 7,
  fourthree: 1,
  lowheat: 2,
  gasworks: 3,
  festival: 5,
}

// Whilst brand is still a nullable field on all things all of these
// functions fall back to the hardcoded Boiler Room brand.

export const getMailingList = (brand: BrandType) => {
  // TODO refactor me so this state isnt stored here
  if (brand.id === brandIds.fourthree) return 'fourThreeMailingList'
  if (brand.id === brandIds.lowheat) return 'lowHeatMailingList'
  if (brand.id === brandIds.gasworks) return 'gasworksMailingList'
  return 'boilerRoomMailingList'
}

export const getFullURL = (brand: ?BrandType) =>
  !brand || !brand.url_prefix ? `//${baseUrl}` : `//${brand.url_prefix}.${baseUrl}`

/**
 * Get all the hostnames that are allowed to view the current brand,
 * excluding micro-sites (which are always allowed on any domain).
 *
 * Note this will include the port if specified.
 */
export const getAllowedBrandHostnames = (brand: ?BrandType) =>
  !brand || !brand.url_prefix ? baseUrlHostnameAlternatives : [`${brand.url_prefix}.${baseUrl}`]

export const getBrandFromId = (brands: Array<BrandType>, brandId: ?number): ?BrandType =>
  brands.find(brand => brand.id === brandId)
