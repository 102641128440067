import React, {useState, useEffect} from 'react'
import {
  useMutation,
  useQuery,
} from '@apollo/client'
import queryString from 'query-string'

import {hasWindow} from '../../common/constants'

import getShowDate from '../../common/getShowDate'

import Page from '../Page'
import Loading from '../Loading'
import Button from '../Button'
import Link from '../Link'
import NotFound from '../NotFound'

import Arrow from '../Svgs/Arrow'
import CompleteTick from '../Svgs/CompleteTick'
import Star from '../Svgs/Star'

import UPDATE_FEEDBACK_MUTATION from './Mutation'
import SHOW_QUERY from '../Show/Query'

import styles from './Feedback.less'

const starRatings = [1, 2, 3, 4, 5]
const positiveTagOptions = ['Safe', 'Music', 'Sound', 'Staff & Security', 'Crowd', 'Visuals', 'Stage']
const negativeTagOptions = ['Unsafe', 'Music', 'Sound', 'Staff & Security', 'Venue', 'Crowd', 'Toilets', 'Queues']
const netPromoterScores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const toggleTag = (tags, tagToToggle) =>
  tags.includes(tagToToggle) ?
    tags.filter(tag => tag !== tagToToggle) : // remove
    [...tags, tagToToggle] // add

// if the new value matches the current value then 'unset' the choice back to null
const toggleMultipleChoice = (set, currentValue, newValue) =>
  set(currentValue === newValue ? null : newValue)

export default props => {
  if (!hasWindow) return <Loading />

  const {show_slug, uuid} = props.match.params

  const [updateFeedback] = useMutation(UPDATE_FEEDBACK_MUTATION)
  const {data: showData, loading: showLoading} = useQuery(SHOW_QUERY, {variables: {slug: show_slug}})

  const {
    rating: initialRating,
    positive_tag: initialPositiveTag,
    negative_tag: initialNegativeTag,
  } = queryString.parse(props.location.search)

  const [rating, setRating] = useState(parseInt(initialRating) || null)
  const [anonymousID, setAnonymousID] = useState(null)
  const [positiveTags, setPositiveTags] = useState(initialPositiveTag ? [initialPositiveTag] : [])
  const [negativeTags, setNegativeTags] = useState(initialNegativeTag ? [initialNegativeTag] : [])
  const [positiveText, setPositiveText] = useState(null)
  const [negativeText, setNegativeText] = useState(null)
  const [wasFirstEvent, setWasFirstEvent] = useState(null)
  const [wouldAttendAgain, setWouldAttendAgain] = useState(null)
  const [netPromoterScore, setNetPromoterScore] = useState(null)
  const [freeTextFocused, setFreeTextFocused] = useState(false)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    updateFeedback({
      variables: {
        show_slug,
        uuid,
        anonymous_id: anonymousID,

        rating,
        positive_tags: positiveTags,
        negative_tags: negativeTags,
        positive_text: positiveText,
        negative_text: negativeText,
        was_first_event: wasFirstEvent,
        would_attend_again: wouldAttendAgain,
        net_promoter_score: netPromoterScore,
        complete,
      },
    })
      .then(({data}) => {
        // Save Anonymous ID in state, created by Stashless
        if (data && data.update_feedback.anonymous_id) {
          setAnonymousID(data.update_feedback.anonymous_id)
        }
        // If the server says feedback is complete, it's complete
        if (data && data.update_feedback.complete) {
          setComplete(true)
        }
      })
  }, [
    rating,
    positiveTags,
    negativeTags,
    wasFirstEvent,
    wouldAttendAgain,
    netPromoterScore,
    complete,
    freeTextFocused,
  ])

  const toggleFocus = () => setFreeTextFocused(!freeTextFocused)

  if (!showLoading && !showData.show) return <NotFound />

  if (showLoading) return <Loading />

  if (complete) return <CompleteMessage />

  return (
    <Page title='Submit Feedback' header={<div />} hideFooter={true}>
      <div className={styles.Wrapper}>
        <Header show={showData.show} />

        <h4>Rate the event</h4>
        <div className={styles.Rating}>
          {starRatings.map(starRating =>
            <Button
              disableDefaultStyles
              onClick={() => setRating(starRating)}
              key={`star-rating-${starRating}`}
              text={<Star filled={rating >= starRating} />}
            />
          )}
        </div>

        <h4>What went well?</h4>
        <p>Select any that apply</p>
        <Tags
          tags={positiveTags}
          tagOptions={positiveTagOptions}
          set={setPositiveTags}
          className={styles.PositiveTags}
        />

        <FreeText
          setValue={setPositiveText}
          value={positiveText}
          toggleFocus={toggleFocus}
        />

        <h4>What didn't go well?</h4>
        <p>Select any that apply</p>
        <Tags
          tags={negativeTags}
          tagOptions={negativeTagOptions}
          set={setNegativeTags}
          className={styles.NegativeTags}
        />

        <FreeText
          setValue={setNegativeText}
          value={negativeText}
          toggleFocus={toggleFocus}
        />

        <p>Was this your first Boiler Room?</p>
        <div className={styles.ButtonGroup}>
          <Button
            text='Yes'
            onClick={() => toggleMultipleChoice(setWasFirstEvent, wasFirstEvent, true)}
            disableDefaultStyles
            className={wasFirstEvent === true ? styles.TagSelected : styles.Tag}
          />
          <Button
            text='No'
            onClick={() => toggleMultipleChoice(setWasFirstEvent, wasFirstEvent, false)}
            disableDefaultStyles
            className={wasFirstEvent === false ? styles.TagSelected : styles.Tag}
          />
        </div>

        <p>Would you come again?</p>
        <div className={styles.ButtonGroup}>
          <Button
            text='Yes'
            onClick={() => toggleMultipleChoice(setWouldAttendAgain, wouldAttendAgain, true)}
            disableDefaultStyles
            className={wouldAttendAgain === true ? styles.TagSelected : styles.Tag}
          />
          <Button
            text='No'
            onClick={() => toggleMultipleChoice(setWouldAttendAgain, wouldAttendAgain, false)}
            disableDefaultStyles
            className={wouldAttendAgain === false ? styles.TagSelected : styles.Tag}
          />
        </div>

        <p>How likely are you to recommend a Boiler Room event to a friend?</p>
        <div className={styles.NPSButtonGroup}>
          {netPromoterScores.map(score =>
            <Button
              text={score}
              key={`nps_${score}`}
              onClick={() => toggleMultipleChoice(setNetPromoterScore, netPromoterScore, score)}
              disableDefaultStyles={true}
              className={score === netPromoterScore ? styles.TagSelected : styles.Tag}
            />
          )}
        </div>
        <div className={styles.NPSLabels}>
          <span>Very unlikely</span>
          <span>Very likely</span>
        </div>

        <Button
          text='Submit'
          type='submit'
          className={styles.SubmitButton}
          onClick={() => setComplete(true)}
        />
      </div>
    </Page>
  )
}

const CompleteMessage = () =>
  <Page title='Thanks' header={<div />} hideFooter={true}>
    <div className={styles.CompleteWrapper}>
      <CompleteTick className={styles.CompleteTick} />
      <h4>Thanks</h4>
      <p>We've received your feedback</p>
      <Link internalLink='/'>
        <span>boilerroom.tv</span>
        <Arrow direction='right' color='#FFF' />
      </Link>
    </div>
  </Page>

const Header = ({show}) =>
  <div className={styles.Header}>
    <img src={require('./ChatIcon.svg')} />
    Submit feedback for<br />
    <b>{show.title}</b>
    <div className={styles.ShowDate}>{getShowDate(show)}</div>
  </div>

const Tags = ({set, tags, tagOptions, className, Icon}) =>
  <div className={className}>
    {tagOptions.map(tagOption =>
      <Button
        disableDefaultStyles
        className={tags.includes(tagOption) ? styles.TagSelected : styles.Tag}
        onClick={() => set(toggleTag(tags, tagOption))}
        key={`tag-${tagOption}`}
        text={
          <div>
            <div className={styles.TagIcon} />
            {tagOption}
          </div>
        }
      />
    )}
  </div>

const FreeText = ({value, setValue, placeholder, toggleFocus}) =>
  <textarea
    placeholder='Comments'
    onChange={e => setValue(e.target.value)}
    onFocus={toggleFocus}
    onBlur={toggleFocus}
  >
    {value}
  </textarea>
