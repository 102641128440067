// @flow
import React from 'react'
import {connect} from 'react-redux'

import type {State as StateType} from '../../stateType'

import {actions as shopActions} from '../Shop/state'

import GET_SHOP_NAVIGATION from './Query'

import Query from '../Query'
import ShopNavComponent from './Component'

const mapStateToProps = ({shop}: StateType) => ({
  shopLocale: shop.shopLocale,
  shopLocaleLoading: shop.shopLocaleLoading,
})

const mapDispatchToProps = {
  changeShopLocale: shopActions.changeShopLocale,
}

type Props = {
  activeSlug?: ?string,
  shopLocale: string,
  changeShopLocale: string => void,
}

const ShopNav = (props: Props) => {
  return (
    <Query
      Component={ShopNavComponent}
      query={GET_SHOP_NAVIGATION}
      variables={{slug: 'boiler-room-main-shop'}}
      props={props}
      fetchPolicy='cache-first'
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopNav)
